@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap");

:root {
  /* --font-base: "Nunito", sans-serif;
  --font-alt: "Nunito Sans", sans-serif; */
  --font-base: "Graphik,Helvetica Neue,Helvetica",sans-serif;
  --font-alt: "Graphik,Helvetica Neue,Helvetica",sans-serif;
  --color-font: #2b394e;
  --accent-color: #646eff;

  --color-one: #f4f4f7;
  --color-two: #ededf1;
  --color-three: #b8b8bd;
}



/* Graphik,Helvetica Neue,Helvetica,sans-serif */