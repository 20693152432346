.catalog__create_form-container__fields{
    display:flex;
    justify-content: end;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin: 1rem 0rem;
    
    
}


.catalog__create_form-container__fields button {
    background: var(--accent-color);
    color: #fff;
    border: 2px solid var(--accent-color);
    padding: 0.9rem 1.5rem;
    border-radius: 50px;
    cursor: pointer;
    outline: none;
    font-weight: 800;
    letter-spacing: 1.5px;
    text-transform: capitalize;
    opacity: 0.8;
    transition: opacity 0.3s ease-in;
  }

  .orders-actions i{
    padding: 4px;
    margin-left: 3px;
    cursor: pointer;
}