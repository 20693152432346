.okiro__homepage__previewPosts {
  width: 100%;
  display: flex;
  flex-direction: row;

  margin: 1rem 0;
}

.okiro__hompage__previewPosts-title {
  width: 25px;
  display: flex;
  position: relative;
  margin-right: 1rem;
}

.okiro__hompage__previewPosts-title h2 {
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  transform: translate(-45%, 70px) rotate(-90deg);
  text-align: right;
  letter-spacing: 8px;
  text-transform: uppercase;
  font-family: var(--font-base);
  width: max-content;
}

.okiro__hompage__previewPosts-zigzag {
  display: none;
}

.okiro__homepage__previewPosts-posts {
  flex: 5;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media screen and (max-width: 1000px) {
  .okiro__homepage__previewPosts-posts {
    justify-content: center;
  }
}

@media screen and (max-width: 900px) {
  .okiro__homepage__previewPosts {
    flex-direction: column;
  }

  .okiro__hompage__previewPosts-title {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    margin-bottom: 1rem;
  }

  .okiro__hompage__previewPosts-title h2 {
    transform: none;
    text-align: center;
    position: unset;

    font-size: 15px;
  }

  .okiro__hompage__previewPosts-zigzag {
    display: flex;
    width: 90px;
    fill: rgba(0, 0, 0, 0.06);
    margin-top: 10px;
  }
}

@media screen and (max-width: 450px) {
  .okiro__homepage__previewPosts {
    padding: 0rem 1rem;
  }
}
