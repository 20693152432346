.catalog__homepage__menu {
    /* width: 15%; */
    display: flex;
    flex-direction: row;
    justify-content: end;
  
    margin: 1rem 0;
  }

  .catalog__menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
 
  }
  
  .catalog__menu__logo {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .catalog__menu__logo img {
    width: 200px;
    height: 60px;
  }
  
  .catalog__menu__links,
  .catalog__menu__sign {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .catalog__menu__sign {
    justify-content: flex-end;
  }
  
  .style-font{
    font-style: italic;
  }
  
  .catalog__menu__links ul,
  .catalog__menu__sign ul {
    display:block;
    justify-content:end;
    align-items: center;
  }
  
  .catalog__menu__links li,
  .catalog__menu__sign li,
  .catalog__menu__hamburger--linksDiv li {
    display: flex;
    list-style: none;
    margin: 1.5rem 3.7rem;
  
    font-family: var(--font-base);
    font-size: 15px;
    line-height: 1.5;
    color: var(--color-font);
    font-weight: 900;
    cursor: pointer;
    position: relative;
    font-weight: 100;
  }
  
  .catalog__menu__links {
    position: relative;
  }

  .catalog__menu__links h3 {
      font-size: 19px;
      margin-left: 5rem;
  }
  
  .catalog__menu__dotLinks {
    display: flex;
    justify-content: flex-start;
    min-width: 170px;
    position: absolute;
  
    margin: 2rem 0rem 0rem -3rem;
  
    background: var(--color-two);
    padding: 1rem 0.7rem;
    border-radius: 10px;
  
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  
  .catalog__menu__dotLinks ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .catalog__menu__dotLinks li {
    text-align: left;
    margin: 0.3rem 0.7rem;
  }

  .btn_ctg__header__dotLinks {
    display: flex;
    justify-content: flex-start;
    min-width: 170px;
    position: absolute;
  
    margin: 2rem 0rem 0rem -3rem;
  
    background: var(--color-two);
    padding: 1rem 0.7rem;
    border-radius: 10px;
  
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  
  .btn_ctg__header__dotLinks ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .btn_ctg__header__dotLinks li {
    text-align: left;
    margin: 0.3rem 0.7rem;
  }
  
  .special-link:hover:before,
  .special-link:hover:before {
    position: absolute;
    width: 7px;
    height: 7px;
    content: "";
    border-radius: 100%;
    background: var(--color-three);
    top: 1px;
    right: -7px;
  
    transition: background-color 0.3s ease;
  }
  
  .special-link:active:before,
  .special-link:focus:before {
    background: var(--accent-color);
  }
  
  .isActive::before {
    background: var(--accent-color);
    position: absolute;
    width: 7px;
    height: 7px;
    content: "";
    border-radius: 100%;
    top: 1px;
    right: -7px;
  }
  
  .catalog__menu__hamburger {
    display: none;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  
    position: relative;
  }
  
  .catalog__menu__hamburger--linksDiv {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 1.5rem;
  
    background-color: var(--color-two);
    top: 170%;
    right: -1%;
    border-radius: 10px;
    min-width: 170px;
    z-index: 5;
  
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  
  .catalog__menu__hamburger--linksDiv ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0rem 1rem;
  }
  
  .catalog__menu__hamburger--linksDiv li {
    margin: 0.3rem;
    font-size: 16px;
    user-select: none;
  }
  
  .catalog__menu__hamburger--linkDiv-search {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: var(--accent-color);
    padding: 1rem;
    margin-top: 0.7rem;
  
    font-family: var(--font-base);
    letter-spacing: 0.5px;
    color: #fff;
    font-weight: 700;
    text-transform: capitalize;
    cursor: pointer;
  
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .catalog__menu__hamburger--linkDiv-search svg {
    margin-left: 10px;
  }

  .btn_content_ctg{
    display: none;
  }

  .btn-flotante-ctg {
    font-size: 8px; /* Cambiar el tamaño de la tipografia */
    text-transform: uppercase; /* Texto en mayusculas */
    font-weight: bold; /* Fuente en negrita o bold */
    color: #E91E63;; /* Color del texto */
    border-radius: 25px; /* Borde del boton */
    letter-spacing: 2px; /* Espacio entre letras */
    background-color: #fff; /* Color de fondo */
    padding: 5px 10px; /* Relleno del boton */
    position: fixed;
    top: 6rem;
    transition: all 300ms ease 0ms;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }


  .btn-flotante-ctg h6{
    font-size: 16px;
    margin-top: -1rem;
      margin-left: 2rem;
  }
  
  .btn-flotante-ctg:hover {
    background-color: #fff; /* Color de fondo al pasar el cursor */
    /* box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3); */
    transform: translateY(-7px);
  }
  @media only screen and (max-width: 600px) {
     .btn-flotante-ctg {
      font-size: 10px;
      padding: 12px 20px;
    }
  } 


  @media screen and (max-width: 1485px) {

    /* .catalog__homepage__menu {
        width: 20%;
      } */

  }
  
  @media screen and (max-width: 1010px) {
    .catalog__homepage__menu{
        display: none;
    }
    
    .catalog__menu__sign,
    .catalog__menu__links {
      display: none;
    }


    .btn_content_ctg{
      display:flex;
    }
  
  
    .catalog__menu__logo {
      justify-content: flex-start;
    }
  
    .catalog__menu__hamburger {
      display: flex;
    }


  }
  
  @media screen and (max-width: 600px) {
    .catalog__menu {
      padding: 1rem;
    }
  
    .catalog__menu__logo img {
      width: 180px;
      height: 50px;
    }

    .catalog__homepage__menu{
        display: none;
    }
  }
  