.okiro__homepage {
  display: flex;
  flex-direction: column;

  padding: 2rem;
}

.okiro__homepage__aritclesDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 2rem 0;
}

.okiro__homepage__show-articles {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  margin-bottom: 1rem;
}

@media screen and (max-width: 1000px) {
  .okiro__homepage__show-articles {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 450px) {
  .okiro__homepage {
    padding: 2rem 0.5rem;
  }
}
