.okiro__authorpage {
  display: flex;
  flex-direction: column;

  margin: 1rem 0;
  padding: 2rem;
  user-select: none;
}

@media screen and (max-width: 450px) {
  .okiro__authorpage {
    padding: 2rem 0.5rem;
  }
}
