
.card {
  margin-bottom: 24px;
  -webkit-box-shadow: 0 2px 3px #e4e8f0;
  box-shadow: 0 2px 3px #e4e8f0;
}
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eff0f2;
  border-radius: 1rem;
}
.activity-checkout {
  list-style: none
}

.activity-checkout .checkout-icon {
  position: absolute;
  top: -4px;
  left: -24px
}

.activity-checkout .checkout-item {
  position: relative;
  padding-bottom: 24px;
  padding-left: 35px;
  border-left: 2px solid #f5f6f8
}

.activity-checkout .checkout-item:first-child {
  border-color: #3b76e1
}

.activity-checkout .checkout-item:first-child:after {
  background-color: #3b76e1
}

.activity-checkout .checkout-item:last-child {
  border-color: transparent
}

.activity-checkout .checkout-item.crypto-activity {
  margin-left: 50px
}

.activity-checkout .checkout-item .crypto-date {
  position: absolute;
  top: 3px;
  left: -65px
}



.avatar-xs {
  height: 1rem;
  width: 1rem
}

.avatar-sm {
  height: 2rem;
  width: 2rem
}

.avatar {
  height: 3rem;
  width: 3rem
}

.avatar-md {
  height: 4rem;
  width: 4rem
}

.avatar-lg {
  height: 5rem;
  width: 5rem
}

.avatar-xl {
  height: 6rem;
  width: 6rem
}

.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #3b76e1;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%
}

.avatar-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 8px
}

.avatar-group .avatar-group-item {
  margin-left: -8px;
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-transition: all .2s;
  transition: all .2s
}

.avatar-group .avatar-group-item:hover {
  position: relative;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px)
}

.card-radio {
  background-color: #fff;
  border: 2px solid #eff0f2;
  border-radius: .75rem;
  padding: .5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block
}

.card-radio:hover {
  cursor: pointer
}

.card-radio-label {
  display: block
}

.edit-btn {
  width: 35px;
  height: 35px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  right: 25px;
  margin-top: -50px
}

.card-radio-input {
  display: none
}

.card-radio-input:checked+.card-radio {
  border-color: #3b76e1!important
}


.font-size-16 {
  font-size: 16px!important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a {
  text-decoration: none!important;
}


.form-control {
  display: block;
  width: 100%;
  padding: 0.47rem 0.75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #545965;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5e8;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.75rem;
  -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.edit-btn {
  width: 35px;
  height: 35px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  right: 25px;
  margin-top: -50px;
}

.ribbon {
  position: absolute;
  right: -26px;
  top: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 1px 22px;
  font-size: 13px;
  font-weight: 500
}

