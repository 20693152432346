.okiro__custom-button {
  display: inline-block;
}

.okiro__custom-button button {
  background: var(--accent-color);
  color: #fff;
  border: 2px solid var(--accent-color);
  padding: 0.9rem 1.5rem;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  font-weight: 800;
  letter-spacing: 1.5px;
  text-transform: capitalize;
  opacity: 0.8;
  transition: opacity 0.3s ease-in;
}

.okiro__custom-button button:hover {
  opacity: 1;
}
