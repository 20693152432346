.okiro__socialicon {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  margin: 0.3rem 0.65rem;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #ededf1;
  transition: transform 0.2s ease;
  cursor: pointer;
}

.okiro__socialicon:hover {
  transform: scale(0.94);
}
