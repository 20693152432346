.okiro__product-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 330px;

  padding: 1.3rem;
  background: #f4f4f7;
  border-radius: 2px;

  margin: 1rem;
  transition: transform 0.2s ease;
}

.okiro__product-card:hover {
  transform: translateY(-4px);
}

.okiro__product-card__image {
  display: flex;
  height: 260px;
  justify-content: end;
}

.okiro__product-card__image .msg_texto {
  position: absolute;
  margin-top: 1.2rem;
  margin-right: 1.5rem;
  
}

.okiro__product-card__image .msg_texto h4 {
  font-size: 30px;
  color: #0fd0d6;
}

.okiro__product-card__image a {
  width: 100%;
}

.okiro__product-card__image img {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  cursor: pointer;
  background:transparent url("../../assets/images/load2.gif") center ;
  background-size:contain;
  
}

.okiro__product-card__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;

  margin: 0.8rem 0.5rem 0rem;
}

.okiro__product-card__content h3 {
  font-size: 16px;
  font-weight: 900;
  color: var(--color-font);
  line-height: 1.5;
  font-family: var(--font-base);
  cursor: pointer;
}

.okiro__product-card__content h3::first-letter {
  text-transform: capitalize;
}

.okiro__product-card__content p {
  font-size: 13px;
  font-family: var(--font-alt);
  font-weight: 800;
  word-break: break-all;
  cursor: pointer;

  text-transform: uppercase;
  color:#a9b1be !important;

  margin-top: 0.4rem;
}

.product-bottom-details {
  overflow: hidden;

  margin: 0.8rem 0.5rem 0rem;
}

.product-bottom-details div {
  float: left;
  width: 50%;
}

.start-sty{
  font-size: 15px;
  color: #fbb72c;
  font-weight: 600;
}

.product-price {
  font-size: 25px;
  /* color: #89bbfe; */
  color: #589efb;
  font-weight: 600;
}

.product-price small {
  font-size: 80%;
  font-weight: 400;
  text-decoration: line-through;
  display: inline-block;
  margin-right: 5px;
}

.product-links {
  text-align: right;
}

.product-links .add__Cart {
  display: inline-block;
  margin-left: 1px;
  color: #f0b43b;
  transition: 0.3s;
  font-size: 20px;
}

.product-links .add__Cart:hover {
  color: #fb2c2c;
  cursor: pointer;
}

@media screen and (max-width: 300px) {
  .okiro__product-card {
    min-width: 100%;
  }
}
