.okiro__connectwithus {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.okiro__connectwithus_image-container {
  flex: 1.5;
  display: flex;
}

.okiro__connectwithus_image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.okiro__connectwithus_form-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 2rem;
}

.okiro__connectwithus_form-container__logo {
  display: flex;

  margin-bottom: 2rem;
}

.okiro__connectwithus_form-container__logo img {
  width: 240px;
  height: 70px;
  cursor: pointer;
}

.okiro__connectwithus_form-container__heading {
  display: flex;
  text-align: center;

  margin-bottom: 2rem;
}

.okiro__connectwithus_form-container__heading h1 {
  font-size: 42px;
  line-height: 1.2;
  font-weight: 900;
  color: var(--color-font);
  font-family: var(--font-base);
}

.okiro__connectwithus_form-container__fields {
  display: flex;
  width: 80%;
  flex-direction: column;

  margin-bottom: 2rem;
}

.okiro__connectwithus_form-container__fields-input {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  margin: 1rem 0rem;
}

.okiro__connectwithus_form-container__fields-input p,
.okiro__connectwithus__form-container__fields-textarea p {
  font-size: 13px;
  text-align: center;
  font-family: var(--font-alt);
  font-weight: 500;
  color: var(--color-font);

  margin-bottom: 0.5rem;
}

.okiro__connectwithus_form-container__fields-input input {
  font-size: 22px;
  text-align: center;
  border-radius: 3rem;
  padding: 1.5rem 1rem;
  background-color: #f4f4f7;
  width: 100%;
  line-height: 1.3;
  font-family: var(--font-alt);
  outline: none;
  border: none;
  transition: background-color 0.15s ease;
}

.okiro__connectwithus_form-container__fields-input input:focus,
.okiro__connectwithus_form-container__fields-input input:active,
.okiro__connectwithus_form-container__fields-input input:hover {
  background-color: #ededf1;
}

.okiro__connectwithus__form-container__fields-textarea {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  margin: 1rem 0rem;
}

.okiro__connectwithus__form-container__fields-textarea textarea {
  width: 100%;
  font-size: 16px;
  transition: border-color 0.15s ease;
  border: 4px solid #f4f4f7;
  border-radius: 2rem;

  outline: none;
  padding: 1rem;
  line-height: 1.3;
  font-family: var(--font-alt);
}

.okiro__connectwithus__form-container__fields-textarea textarea:focus,
.okiro__connectwithus__form-container__fields-textarea textarea:active {
  border-color: var(--accent-color);
}

.okiro__connectwithus_form-container__fields-sign {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 1rem 0;
}

.okiro__connectwithus_form-container__fields-sign p {
  font-size: 13px;
  text-align: center;
  font-family: var(--font-base);
  font-weight: 400;
  color: var(--color-font);
  line-height: 1.5;
}

.okiro__connectwithus_form-container__fields-sign span {
  font-weight: 900;
  cursor: pointer;
}

@media screen and (max-width: 950px) {
  .okiro__connectwithus {
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
    align-items: center;
  }

  .okiro__connectwithus_image-container {
    height: 100px;
  }

  .okiro__connectwithus_form-container {
    width: 100%;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 570px) {
  .okiro__connectwithus_form-container {
    padding: 2rem 0.5rem;
  }

  .okiro__connectwithus_form-container__heading h1 {
    font-size: 22px;
  }

  .okiro__connectwithus_form-container__fields input {
    padding: 1rem;
  }
}

@media screen and (max-width: 380px) {
  .okiro__connectwithus_form-container__fields {
    width: 95%;
  }
}
