.okiro__seealso {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 2rem;
  text-align: center;
}

.okiro__seealso h3 {
  font-size: 22px;
  font-weight: 900;
  font-family: var(--font-base);
  line-height: 1.5;
  color: var(--color-font);
}

.okiro__seealso-div {
  margin: 1rem 0rem;
  display: flex;
  flex-direction: row;

  background: #f4f4f7;
  border-radius: 1.2rem;
  padding: 1rem;

  width: 600px;
  transition: transform 0.2s ease;
}

.okiro__seealso-div:hover {
  transform: translateY(-4px);
}

.okiro__seealso-div__img,
.okiro__seealso-div__name {
  display: flex;
  justify-content: center;
  align-items: center;
}

.okiro__seealso-div__img img {
  width: 90px;
  height: 90px;
  border-radius: 0.5rem;
  cursor: pointer;
}

.okiro__seealso-div__name p {
  line-height: 1.1;
  font-size: 19px;
  font-weight: 900;
  font-family: var(--font-base);
  color: var(--color-font);
  cursor: pointer;
  margin-left: 1rem;
}

.okiro__seealso-div__name p::first-letter {
  text-transform: capitalize;
}

.shopping__button_products__fields_old {
  justify-content: center;
  margin-right: 5px;
}


.shopping__button_products__fields_old button{

  background-color: #b1b1b1;
  border: 2px solid #b1b1b1;;

}


.product-detail {
  width: 600px;
  padding: 24px;
  box-sizing: border-box;
  position:inherit;
}
.title-container {
  display: flex;
}
.title-container img {
  transform: rotate(180deg);
  margin-right: 14px;
}
.title {
  font-size: var(--lg);
  font-weight: bold;
}
.order {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  align-items: center;
  background-color: var(--text-input-field);
  margin-bottom: 24px;
  border-radius: 8px;
  padding: 0 24px;
}
.order p:nth-child(1) {
  display: flex;
  flex-direction: column;
}
.order p span:nth-child(1) {
  font-size: var(--md);
  font-weight: bold;
}
.order p:nth-child(2) {
  text-align: end;
  font-weight: bold;
}
.shopping-cart {
  display: grid;
  grid-template-columns: auto 1fr auto auto auto;
  gap: 16px;
  margin-bottom: 24px;
  align-items: center;
}
.shopping-cart figure {
  margin: 0;
}
.shopping-cart figure img {
  width: 70px;
  height: 70px;
  border-radius: 20px;
  object-fit: cover;
}

.shopping-cart .del_shopping{
  cursor: pointer;
}

.shopping-cart p:nth-child(2) {
  color: var(--very-light-pink);
}
.shopping-cart p:nth-child(3) {
  font-size: var(--md);
  font-weight: bold;
}
.primary-button {
  background-color: var(--hospital-green);
  border-radius: 8px;
  border: none;
  color: var(--white);
  width: 100%;
  cursor: pointer;
  font-size: var(--md);
  font-weight: bold;
  height: 50px;
}
@media (max-width: 640px) {
  .product-detail {
    width: 100%;
  }
}





@media screen and (max-width: 450px) {
  .okiro__seealso-div {
    width: auto;
  }

  .okiro__seealso-div__name {
    text-align: left;
  }
}

@media screen and (max-width: 270px) {
  .okiro__seealso-div {
    flex-direction: column;
  }

  .okiro__seealso-div p {
    margin: 1rem 0rem 0rem;
  }

  .okiro__seealso-div__name {
    text-align: center;
  }
}
