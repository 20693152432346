.toast-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  padding: 20px;
}

.toast-container .toast .toast-header.text-bg-success {
  background-color: #5cb85c;
  color: #fff;
}

.toast-container .toast .toast-header.text-bg-error {
  background-color: #d9534f;
  color: #fff;
}

.toast-container .toast .toast-header.text-bg-warning {
  background-color: #f0ad4e;
  color: #fff;
}

.toast-container .toast .toast-header.text-bg-info {
  background-color: #5bc0de;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .toast-container {
    right: 50%;
    transform: translateX(50%);
  }
}