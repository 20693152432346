.okiro__article-card {
  /* min-width: 290px; */
  display: flex;
  flex-direction: column;
  max-width: 350px;

  background: #ffeab9;
  border-radius: 25px;

  margin: 1rem 1.5rem;

  transition: transform 0.2s ease;
}

.okiro__article-card:hover {
  transform: translateY(-4px);
}

.okiro__article-card_img {
  display: flex;
  width: 100%;
}

.okiro__article-card_img a {
  width: 100%;
}

.okiro__article-card_img img {
  width: 100%;
  height: 330px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  cursor: pointer;
}

.okiro__article-card_content {
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.okiro__article-card_content-name {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}

.okiro__article-card_content-name h3 {
  font-size: 26px;
  line-height: 1.2;
  width: 100%;
  color: var(--color-font);
  font-weight: 900;
  font-family: var(--font-base);
  cursor: pointer;
}

.okiro__article-card_content-name p {
  margin-top: 0.5rem;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 800;
  color: var(--color-font);
  width: 100%;
  font-family: var(--font-alt);
  cursor: pointer;
}

.okiro__article-card_content-tag {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.okiro__article-card_content-tag_private {
  width: 35px;
  height: 35px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.okiro__article-card_content-tag_private svg {
  fill: var(--color-font);
}
