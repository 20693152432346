.okiro__post-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 210px;
  max-width: 290px;

  padding: 1.3rem;
  background: #f4f4f7;
  border-radius: 20px;

  margin: 1rem;
  transition: transform 0.2s ease;
}

.okiro__post-card:hover {
  transform: translateY(-4px);
}

.okiro__post-card__image {
  display: flex;
  height: 175px;
}

.okiro__post-card__image a {
  width: 100%;
}

.okiro__post-card__image img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
}

.okiro__post-card__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;

  margin: 0.8rem 0.5rem 0rem;
}

.okiro__post-card__content h3 {
  font-size: 19px;
  font-weight: 900;
  color: var(--color-font);
  line-height: 1.5;
  font-family: var(--font-base);
  cursor: pointer;
}

.okiro__post-card__content h3::first-letter {
  text-transform: capitalize;
}

.okiro__post-card__content p {
  font-size: 13px;
  font-family: var(--font-alt);
  font-weight: 800;
  color: var(--color-font);
  word-break: break-all;
  cursor: pointer;

  margin-top: 0.4rem;
}

@media screen and (max-width: 300px) {
  .okiro__post-card {
    min-width: 100%;
  }
}
