.btn-flotante {
	font-size: 30px; /* Cambiar el tamaño de la tipografia */
	text-transform: uppercase; /* Texto en mayusculas */
	font-weight: bold; /* Fuente en negrita o bold */
	color: #000; /* Color del texto */
	border-radius: 25px; /* Borde del boton */
	letter-spacing: 2px; /* Espacio entre letras */
	background-color: transparent; /* Color de fondo */
	padding: 5px 10px; /* Relleno del boton */
	position: fixed;
	top: 6rem;
	right: -1rem;
	height: 3.5rem;
	width: 5.5rem;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
.btn-flotante FaShoppingCart {
    margin-top: 10px;
}
.btn-flotante h6{
	font-size: 16px;
	color: red;
	margin-top: -2.5rem;
    margin-left: 2rem;
}

.btn-flotante:hover {
	background-color: transparent; /* Color de fondo al pasar el cursor */
	/* box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3); */
	transform: translateY(-7px);
}
@media only screen and (max-width: 600px) {
 	.btn-flotante {
		font-size: 24px;
		padding: 12px 20px;
		bottom: 20px;
		top: 5.5rem;
		/* right: 12px; */
	}

	.btn-flotante h6{
		margin-top: -2.3rem;
		margin-left: 1.7rem;
	}
} 