.okiro__homepage__latest {
  display: flex;
  flex-direction: row;
  margin: 3rem 0;
}

.okiro__homepage__latest-img {
  flex: 4;
  display: flex;
  max-height: 600px;
  margin: 0rem 1rem;
}

.okiro__homepage__latest-img img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  object-fit: cover;
  cursor: pointer;
}

.okiro__homepage__latest-img a {
  width: 100%;
}

.okiro__homepage__latest-content {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 1rem;
}

.okiro__homepage__latest-content_tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.all_tags {
  margin: 0.2rem;
}

.all_tags:first-child {
  margin-left: 0;
}

.all_tags:last-child {
  margin-right: 0;
}

.okiro__homepage__latest-content h2 {
  margin-top: 1rem;
  width: 100%;
  font-size: 54px;
  color: var(--color-font);
  line-height: 1.2;
  font-weight: 900;
  font-family: var(--font-base);
  cursor: pointer;
  margin-bottom: 3rem;
}

.okiro__homepage__latest-content_shortpara {
  display: flex;
  flex-direction: column;
  margin: 0rem 0rem 2rem;
}

.okiro__homepage__latest-content_shortpara svg {
  width: 90px;
  fill: rgba(0, 0, 0, 0.06);
}

.okiro__homepage__latest-content_shortpara p {
  margin-top: 0.5rem;
  font-family: var(--font-alt);
  font-size: 18px;
  font-weight: 800;
  width: 90%;
  color: var(--color-font);
  line-height: 1.5;
}

.okiro__author {
  display: flex;
  flex-direction: row;
}

.okiro__author-img {
  display: flex;
}

.okiro__author-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.okiro__author-info {
  margin-left: 0.7rem;
  display: flex;
  flex-direction: column;
}

.okiro__author-info h3 {
  margin-bottom: 0.1rem;
  font-family: var(--font-base);
  color: var(--color-font);
  font-size: 16px;
  cursor: pointer;
  margin-top: 0rem;
}

.okiro__author-info p {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  font-family: var(--font-alt);
  color: var(--color-font);
}

@media screen and (max-width: 1000px) {
  .okiro__homepage__latest-content_shortpara {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .okiro__homepage__latest {
    flex-direction: column;
  }

  .okiro__homepage__latest-content_shortpara {
    display: flex;
  }

  .okiro__homepage__latest-img {
    margin-top: 1rem;
  }

  .okiro__homepage__latest-content {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 600px) {
  .okiro__homepage__latest-content h2 {
    font-size: 36px;
  }

  .okiro__homepage__latest-img {
    max-height: 450px;
  }
}

@media screen and (max-width: 450px) {
  .okiro__homepage__latest-img {
    max-height: 370px;
    margin: 0 0.5rem;
  }
}
