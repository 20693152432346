.okiro__blogpage {
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
}

.okiro__blogpage .okiro__homepage__latest {
  margin-top: 1rem;
}

.okiro__blogpage__social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.okiro__blogpage__nextpre {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 2rem;
}

.okiro__blogpage__nextpre a {
  width: 100%;
}

.okiro__blogpage-seemorePosts {
  display: flex;
  flex-direction: row;

  padding: 1rem 2rem;
}

.okiro__blogpage-error {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
}

.okiro__blogpage-error div {
  display: flex;

  background: #fff5ea;
  border-radius: 1rem;
}

.okiro__blogpage-error div h2 {
  margin: 0;
  padding: 1rem;
  line-height: 1.5;
  font-weight: 900;
  color: var(--color-font);
}

@media screen and (max-width: 1120px) {
  .okiro__blogpage__nextpre {
    flex-direction: column;
  }
}

@media screen and (max-width: 800px) {
  .okiro__blogpage {
    padding: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .okiro__blogpage {
    padding: 2rem 0.5rem;
  }
}

@media screen and (max-width: 450px) {
  .okiro__blogpage-seemorePosts {
    padding: 1rem 0.5rem;
  }
}
